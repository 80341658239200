import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedDivisionApplications: string[];
}

const initialState: InitialState = {
  selectedDivisionApplications: [],
};

export const selectedDivisionApplicationsSlice = createSlice({
  name: 'selectedDivisionApplications',
  initialState,
  reducers: {
    addDivisionApplication: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplications = [...state.selectedDivisionApplications, payload];
    },
    removeDivisionApplication: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplications = state.selectedDivisionApplications.filter((item) => item !== payload);
    },
    setSelectedDivisionApplications: (state, { payload }: PayloadAction<string[]>) => {
        state.selectedDivisionApplications = payload;
      },
  }
});

export const {  addDivisionApplication, removeDivisionApplication, setSelectedDivisionApplications } = selectedDivisionApplicationsSlice.actions;
