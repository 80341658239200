import { Stack } from 'components/UI';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const EditLayout = (): JSX.Element => {
  /* ******************** Renderer ******************** */
  return (
    <Stack flexDirection="column">
      <Outlet />
    </Stack>
  );
};
